import { useWindowMessage } from '#imports';
import { UnlockAudio } from '@fanatics/resn-api/instant-rips';
import { noop } from '@vueuse/core';
import { Howl, Howler } from 'dm-howler';
import { defineComponent, inject, onMounted, provide, ref, watchEffect } from 'vue';

import { gsap } from '@resn/gsap';

import { useParentSearchParams } from '~/components/useParentSearchParams';

const AudioKey = 'audio';

export const useAudio = () => {
    const env = inject(AudioKey);
    if (!env) {
        throw new Error('useAudio must be used within a AudioProvider');
    }
    return env;
};

const createSound = (src, { onunlock = noop, loop = false, volume = 1 } = {}) => {
    const sound = new Howl({ src: [src], loop, onunlock, volume });
    sound.baseVolume = volume;

    return sound;
};

const createLoopSound = (src, { start = 0, end = 0, volume = 1 } = {}) => {
    return new Howl({
        src: [src],
        loop: true,
        volume,
        sprite: [{ loop: [start, end, true] }],
    });
};

const AudioProvider = defineComponent({
    setup() {
        // Disable auto unlocking
        Howler.autoUnlock = false;

        const MAX_VOLUME = 0.5;

        const muted = ref(false);
        const volume = ref(0);
        const unlocked = ref(Howler.state === 'running');
        const disableAmbientAudio = ref(false);

        let interval = null;
        onMounted(() => {
            document.body.addEventListener('click', () => unlockAudio(true), { once: true });

            interval = setInterval(() => {
                unlockAudio();
            }, 1000);
        });

        useParentSearchParams((params) => {
            if (params.get('disableAmbientAudio') === 'true') {
                disableAmbientAudio.value = true;
            }
        });

        // Function to manually unlock audio
        const unlockAudio = (fromClick = false) => {
            if (unlocked.value) return;
            // console.log('Attempt unlockAudio', Howler .ctx.state);

            if (Howler.ctx) {
                if (Howler.ctx.state == 'running') {
                    onUnlockAudio();
                } else if (fromClick) {
                    Howler.ctx.resume().then(() => {
                        onUnlockAudio();
                    });
                }
            }
        };

        const onUnlockAudio = () => {
            unlocked.value = true;
            clearInterval(interval);
            // console.log('Audio context unlocked successfully');

            gsap.to(volume, { value: MAX_VOLUME, duration: 3 });
        };

        useWindowMessage({
            onReceiveMessage: (event) => {
                if (event.data.type === UnlockAudio) unlockAudio(true);
            },
        });

        const sounds = {
            // empty: createSound('', { onunlock: onUnlockAudio }),
            boxOpen: createSound('/audio/01_BOX_OPEN.mp3'),
            boxReveal: createSound('/audio/01_BOX_REVEAL.mp3'),
            packDisolve: createSound('/audio/02_PACK_DISSOLVE.mp3'),
            cardStaticState: createSound('/audio/03_CARD_STATIC_STATE.mp3', {
                loop: true,
                volume: 0.4,
            }),
            // cardStaticState: createLoopSound('/audio/03_CARD_STATIC_STATE.mp3', {
            //     start: 0,
            //     end: 6000,
            // }),
            cardReveal: createSound('/audio/04_CARD_REVEAL.mp3'),
            cardRotate: createSound('/audio/05_CARD_ROTATE.mp3'),
            cardFlip: createSound('/audio/06_CARD_FLIP.mp3'),
            nextPrev: createSound('/audio/07_NEXT_PREV.mp3'),
            fadeAway: createSound('/audio/08_FINAL_CARD_FADEAWAY.mp3'),
            cardInfoReveal: createSound('/audio/09_INSERT_CARD_REVEAL.mp3'),
            teamInfoReveal: createSound('/audio/10_TEAM_META_DATA_REVEAL.mp3'),
            playerInfoReveal: createSound('/audio/11_TEAM_META_DATA_REVEAL.mp3'),
            lightsDownReveal: createSound('/audio/12_LIGHTS_DOWN_REVEAL.mp3'),
            heartbeat: createSound('/audio/13_HEARTBEAT.mp3'),
        };

        const play = (id, { fade = false, unique = true, ignoreUnlocked = false } = {}) => {
            // console.log('🚀 ~ play ~ id:', id);
            if (unlocked.value || ignoreUnlocked) {
                const sound = sounds[id];
                const volume = sound.volume();

                sound.fadeDelay?.kill();

                if (fade) {
                    const duration = 1000;
                    sound.fade(volume, sound.baseVolume, duration);
                }

                if (!sound.playing() || unique) {
                    sound.play();
                }
            }
        };

        const pause = (id, { fade = false } = {}) => {
            // console.log('🚀 ~ pause ~ id:', id);
            if (unlocked.value) {
                const sound = sounds[id];
                const volume = sound.volume();

                sound.fadeDelay?.kill();

                if (fade) {
                    const duration = 1;
                    sound.fade(volume, 0, duration * 1000);
                    sound.fadeDelay = gsap.delayedCall(duration, () => {
                        sound.pause();
                    });
                } else {
                    sound.pause();
                }
            }
        };

        const playLoop = (id) => {
            // console.log('🚀 ~ playLoop ~ id:', id);
            if (unlocked.value) sounds[id].play('loop');
        };

        const providerData = {
            volume,
            muted,
            sounds,
            play,
            pause,
            playLoop,
            disableAmbientAudio,
            get unlocked() {
                return unlocked.value;
            },
        };

        watchEffect(() => {
            Howler.volume(volume.value);
            Howler.mute(muted.value);
        });

        provide(AudioKey, providerData);
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default AudioProvider;
